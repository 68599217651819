.event-container {
    justify-content: center;
    grid-row-start: 2;
    grid-row-end: 3;
    .event-form-container {
        width: 640px;
        padding-top: 3rem;
        padding-bottom: 3rem;
        margin: 0 auto;
        h1 {
            span {
                font-family: 'Lab Grotesque Italic';
                font-weight: 200;
            }
        }
        .event-subtitle{
            margin-top: -30px;
            margin-bottom: 20px;

        }
        .event-info-content {
            margin: 30px 0px 30px 0px;
        }
        .your-details-form-content {
            margin: 0px 0px 50px 0px;
            h3 {
                font-family: 'Lab Grotesque';
                text-decoration: underline;
                margin: 0px 0px 30px 0px;
            }
            .ant-form-item {
                margin: 0px 0px 15px 0px;
            }
        }
        .invitation-details-content {
            margin: 0px 0px 50px 0px;
            h3 {
                font-family: 'Lab Grotesque';
                text-decoration: underline;
                margin: 0px 0px 30px 0px;
            }
            .ant-form-item {
                margin: 0px 0px 15px 0px;
            }
        }
        h1 {
            margin: 0px 0px 30px 0px;
            font-weight: bold;
            font-size: 40px;
        }
        .input-form {
            button {
                width: 100%;
            }
        }
        .form-note {
            max-width: 100%;
            margin: 0 auto;
            font-style: italic;
            text-align: center;
            padding: 1rem 1rem;
            font-size: 14px;
            color: #000;
        }
    }
    .event-thank-you-container {
        width: 540px;
        max-width: 100%;
        padding-top: 3rem;
        padding-bottom: 3rem;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        h1 {
            font-size: 42px;
            line-height: 1.4em;
            margin-bottom: 2rem;
            span {
                font-family: 'Lab Grotesque Italic';
                font-weight: 200;
            }
        }
    }
    .event-error-container {
        width: 540px;
        padding-top: 3rem;
        padding-bottom: 3rem;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        h1 {
            font-size: 42px;
            line-height: 1.4em;
            margin-bottom: 2rem;
        }
    }
}

@media only screen and (max-width: 920px) {
    .event-container {
        .event-form-container {
            width: unset;
            padding: 2rem 2rem;
        }
        .event-thank-you-container {
            padding: 2rem 2rem;
        }
        .event-error-container {
            padding: 2rem 2rem;
        }
    }
}
.home-container {
    display: grid;
    grid-row-start: 2;
    grid-row-end: 3;
    .home-content {
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        margin: 0 auto;
        padding: 0 5rem;
        .home-video-container {
            width: 35%;
            max-width: 100%;
            margin: 0px 5rem 0px 0px;
            position: relative;
            bottom: 50px;
            img {
                width: 100%;
            }
        }
        .home-content-container {
            width: 45%;
            max-width: 100%;
            h1 {
                margin: 0px 0px 30px 0px;
                font-weight: bold;
                font-size: 40px;
                span {
                    font-family: 'Lab Grotesque Italic';
                    font-weight: 200;
                }
            }
            .event-subtitle{
                margin-top: -30px;
                margin-bottom: 20px;
    
            }
            ol {
                padding: 0px 15px;
            }
            .home-info-content {
                margin: 30px 0px 0px 0px;
            }
        }
    }
    .home-actions {
        margin: 50px 0px;
        .info-message {
            margin: 20px 0px;
            font-size: 14px;
            width: 400px;
            max-width: 100%;
        }
    }
}

@media only screen and (max-width: 1200px) {
    .home-container {
        .home-content {
            padding: 0 2rem;
            .home-video-container {
                width: 30%;
                margin: 0px 5rem 0px 0px;
                position: relative;
                bottom: 50px;
            }
            .home-content-container {
                width: 60%;
            }
        }
    }
}

@media only screen and (max-width: 920px) {
    .home-container {
        .home-content {
            padding: 0 2rem;
            flex-direction: column;
            .home-video-container {
                width: 100%;
                margin: 30px auto;
                position: relative;
                bottom: 0px;
            }
            .home-content-container {
                width: 100%;
                h1 {
                    text-align: center;
                }
            }
        }
    }
}
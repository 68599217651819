@import '~antd/dist/antd.css';

@font-face {
  font-family: 'Gothia Serif';
  src: url('./assets/fonts/GothiaSerif-Bold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Lab Grotesque';
  src: url('/assets/fonts/LabGrotesque-Regular.woff2') format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Lab Grotesque Italic';
  src: url('/assets/fonts/LabGrotesque-LightItalic.woff') format("woff");
  font-weight: 200;
  font-style: italic;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Gothia Serif', serif;
  font-style: normal;
  font-weight: 600;
  color: #000;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-btn-primary {
  color: #fff;
  border-color: #000;
  background: #000;
  width: 200px;
}

.ant-btn-primary:hover, .ant-btn-primary:focus {
  border-color: #000;
  background: #000;
}

.ant-col-24.ant-form-item-label {
  padding: 0px;
}

ul {
  list-style: none;  
  margin: 0px;
  padding: 0px;    
}
.event-date-container {
    
    display: flex;
    flex-direction: row;
    border: 1px solid #efefef;
    padding: 25px 0px;
    justify-content: center;

    .event-date-item-content {
        width: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        &.borders {
            border-left: 1px solid #efefef;
            border-right: 1px solid #efefef;
        }
        h3 {
            font-family: 'Lab Grotesque', serif;
            margin: 0px;
            font-size: 18px;
            text-align: center;
        }
        h5 {
            font-family: "Lab Grotesque", serif;
            margin: 0px 0px 5px 0px;
            font-size: 15px;
        }
        .map-link {
            font-size: 10px;
            cursor: pointer;
            text-decoration: underline;
        }
    }
}


@media only screen and (max-width: 920px) {
    .event-date-container {
        flex-direction: column;
        align-items: center;
        .event-date-item-content {
            &.borders {
                border-left: 0px solid #efefef;
                border-right: 0px solid #efefef;
                border-top: 1px solid #efefef;
                border-bottom: 1px solid #efefef;
                padding: 20px 0px;
                margin: 20px 0px;
            }
        }
    }
}